<template>
  <div class="page-companies row">
    <EmptyState
      v-if="showBlankState"
      :to="{ name: 'create-company' }"
      icon="jbsmd-building"
      :message="$gettext('You don\'t have any companies')"
      :customButtonText="$gettext('Create a company')"
    />
    <div v-show="!showBlankState" class="col-md-12">
      <ContentHeader :title="$gettext('Companies')">
        <template #description>
          {{ companyLimitMessage }}
        </template>
        <template #options>
          <router-link
            :to="{ name: 'subscription' }"
            class="btn btn-outline-primary mr-2"
          >
            <i class="fas fa-arrow-up mr-2"></i>
            <translate>Increase your limit</translate></router-link
          >
          <router-link
            v-if="canManageCompanies"
            :disabled="hasReachedProductLimit"
            class="btn btn-primary align-self-baseline"
            to="create-company"
            ><i class="fas fa-plus mr-2"></i>
            <translate>Add Company</translate></router-link
          >
        </template>
      </ContentHeader>

      <AdvancedTable store="companies" :fields="fields">
        <template #cell(logo)="data">
          <router-link :to="toCompanyDetailsPage(data.item.id)">
            <img
              :src="data.item.logo"
              alt="Company logo"
              class="company-logo rounded mr-3"
            />
          </router-link>
        </template>
        <template #cell(name_fr)="data">
          <router-link :to="toCompanyDetailsPage(data.item.id)">
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(actions)="data">
          <b-button
            @click="handleView(data)"
            variant="info"
            class="btn btn-info btn-icon btn-sm"
            v-b-tooltip.hover
            :title="$gettext('View')"
            ><i class="fa fa-eye"></i
          ></b-button>

          <b-button
            v-if="canManageCompanies"
            @click="handleDelete(data)"
            variant="danger"
            class="btn btn-info btn-icon btn-sm ml-1"
            v-b-tooltip.hover
            :title="$gettext('Delete')"
            ><i class="fa fa-times"></i
          ></b-button>
        </template>
      </AdvancedTable>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { msgBoxConfirm } from "@/utils/modal";
import EmptyState from "@/components/EmptyState";
import ContentHeader from "@/components/ContentHeader";
import AdvancedTable from "@/components/AdvancedTable";
import { VBTooltip } from "bootstrap-vue";
Vue.directive("b-tooltip", VBTooltip);

export default {
  components: { EmptyState, ContentHeader, AdvancedTable },
  data() {
    return {
      fields: [
        { key: "logo" },
        {
          key: "name_fr",
          label: this.$gettext("Name"),
          sortable: true
        },
        { key: "email", label: this.$gettext("Email"), sortable: true },
        { key: "phone_number", label: this.$gettext("Phone"), sortable: true },
        { key: "website", label: this.$gettext("Website"), sortable: true },
        { key: "location.address", label: this.$gettext("Address") },
        { key: "employee_count", label: this.$gettext("#Employees") },
        { key: "status", label: this.$gettext("Status") },
        { key: "actions", tdClass: "text-right" }
      ]
    };
  },

  computed: {
    ...mapGetters("companies", [
      "productUsage",
      "productLimit",
      "hasReachedProductLimit",
      "showBlankState"
    ]),
    ...mapGetters("app", ["isFetching"]),
    ...mapGetters("me", ["canManageCompanies"]),
    companyLimitMessage() {
      const translated = this.$ngettext(
        "%{ usage } company over your limit of %{ limit }",
        "%{ usage } companies over your limit of %{ limit }",
        this.productUsage
      );
      return this.$gettextInterpolate(translated, {
        usage: this.productUsage,
        limit: this.productLimit
      });
    }
  },
  methods: {
    ...mapActions("companies", ["delete"]),
    handleDelete({ item }) {
      msgBoxConfirm().then(value => {
        if (value.isConfirmed === true) {
          this.delete(item.id);
        }
      });
    },
    handleView({ item }) {
      this.$router.push({
        name: "company-details",
        params: { companyId: item.id }
      });
    },
    toCompanyDetailsPage(companyId) {
      return {
        name: "company-details",
        params: { companyId: companyId }
      };
    }
  }
};
</script>
